<template>
  <div class="userinfo">
    <div class="userinfo-title-list">
      <div class="userinfo-title-item userinfo-cursor" :class="[item.id == selectId ? 'userinfo-title-active':'']"
        @click="clickselectBox($event)" :data-id="item.id" v-for="item in selectTitle" :key="item.id">{{item.name}}
      </div>
    </div>

    <!-- 学员信息 -->
    <div v-if="selectId == 1">
      <div class="userinfo-student">
        <el-form ref="form" :model="UserInfo" label-width="120px" size="small">
          <el-form-item label="头像">
            <div class="userinfo-student-img-box">
              <div class="userinfo-student-img">
                <img :src="UserInfo.headimg" alt="">
              </div>
              <div>如需更改头像请联系平台客服</div>
            </div>
          </el-form-item>
          <el-form-item label="姓名">
            <el-input v-model="UserInfo.name" disabled></el-input>
          </el-form-item>
          <el-form-item label="学号">
            <el-input v-model="UserInfo.student_number" disabled></el-input>
          </el-form-item>
          <el-form-item label="性别">
            <el-select v-model="UserInfo.sex_txt" disabled placeholder="请选择性别">
              <el-option v-for="item in sexList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学历">
            <el-select v-model="UserInfo.education_cate_name" disabled placeholder="请选择学历">
              <el-option v-for="item in educationNameList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="身份号">
            <el-input v-model="UserInfo.id_card" disabled></el-input>
          </el-form-item>
          <el-form-item label="学员账号">
            <el-input v-model="UserInfo.username" disabled></el-input>
          </el-form-item>
          <el-form-item label="手机号码">
            <el-input v-model="UserInfo.mobile" :disabled="closeDisabled"></el-input>
          </el-form-item>
          <el-form-item label="登录密码">
            <el-input v-model="UserInfo.name" type="password" disabled></el-input>
          </el-form-item>
          <el-form-item label="工作单位">
            <el-input v-model="UserInfo.enterprise_name" :disabled="closeDisabled"></el-input>
          </el-form-item>
          <el-form-item label="邮寄地址">
            <el-input v-model="UserInfo.mailing_addr" :disabled="closeDisabled"></el-input>
          </el-form-item>
          <el-form-item label="邮箱">
            <el-input v-model="UserInfo.email" :disabled="closeDisabled"></el-input>
          </el-form-item>
          <el-form-item label="身份证扫描件">
            <el-upload :action="uploadImg" :on-success="handleIdPhoto1Success" :before-upload="beforeAvatarUpload" :disabled="closeDisabled" list-type="picture-card" :show-file-list="true">
              <img width="100%" v-if="UserInfo.id_photo1" :src="UserInfo.id_photo1">
              <i class="el-icon-plus" v-else></i>
            </el-upload>
            <el-upload :action="uploadImg" :on-success="handleIdPhoto2Success" :before-upload="beforeAvatarUpload" :disabled="closeDisabled" list-type="picture-card" :show-file-list="true">
              <img width="100%" v-if="UserInfo.id_photo2" :src="UserInfo.id_photo2">
              <i class="el-icon-plus" v-else></i>
            </el-upload>
            <div class="reg-user-info-master"> *请上传1寸免冠白底证件照，否则将影响证书办理，后果自负！(仅支持上传JPG、PNG格式，大小为200KB内)</div>
          </el-form-item>
          <el-form-item label="毕业证扫描件">
            <el-upload :action="uploadImg" :on-success="handleDiplomaPhotoSuccess" :before-upload="beforeAvatarUpload" :disabled="closeDisabled" list-type="picture-card" :show-file-list="true">
              <img width="100%" v-if="UserInfo.diploma_photo" :src="UserInfo.diploma_photo">
              <i class="el-icon-plus" v-else></i>
            </el-upload>
            <div class="reg-user-info-master"> *请上传毕业证扫描件，仅支持上传JPG、PNG格式，大小为500KB内</div>
          </el-form-item>
        </el-form>
        <div class="userinfo-student-btn" v-if="closeDisabled">
          <el-button type="primary" @click="editSubmit()">编辑资料</el-button>
        </div>
        <div class="userinfo-student-btn" v-else>
          <el-button type="primary" @click="sureSubmit()">保存修改</el-button>
          <el-button @click="closeSubmit()">取消</el-button>
        </div>
      </div>
    </div>
    <!-- 发证记录 -->
    <div v-if="selectId == 2">
      <div class="userinfo-student">
        <el-table :data="certificateList" style="width: auto" :header-cell-style="{background:'#eef0f7bf'}"
          v-loading="loading" element-loading-text="数据正在加载中" element-loading-spinner="el-icon-loading">
          <el-table-column prop="certificate_id" label="序号" width="auto" align="center"></el-table-column>
          <el-table-column prop="zscode" label="证书编号" width="auto" align="center"></el-table-column>
          <el-table-column prop="curriculum_name" label="课程名称" width="auto" align="center"></el-table-column>
          <el-table-column prop="classes_name" label="所在班级" width="auto" align="center"></el-table-column>
          <el-table-column prop="curriculum_cate_name" label="课程类型" width="auto" align="center"></el-table-column>
          <el-table-column prop="teacher" label="班主任" width="auto" align="center"></el-table-column>
          <el-table-column prop="create_time" label="发证日期" width="auto" align="center"></el-table-column>
        </el-table>
      </div>
    </div>



    <div v-if="selectId == 3">
      <el-table :data="invoiceLists" style="width: auto" :header-cell-style="{background:'#eef0f7bf'}"
        v-loading="loading" element-loading-text="数据正在加载中" element-loading-spinner="el-icon-loading">
        <el-table-column prop="id" label="序号" width="auto" align="center"></el-table-column>
        <el-table-column prop="curriculum_name" label="课程名称" width="auto" align="center"></el-table-column>
        <el-table-column prop="create_time" label="申请时间" width="auto" align="center"></el-table-column>
        <el-table-column prop="" label="开票类型" width="auto" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.invoice_id1 == 1">电子发票</span>
            <span v-if="scope.row.invoice_id1 == 2">专用发票</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="发票类型" width="auto" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.invoice_id2 == 1">普通发票（个人）</span>
            <span v-if="scope.row.invoice_id2 == 2">普通发票（企业）</span>
            <span v-if="scope.row.invoice_id2 == 3">单位</span>
          </template>
        </el-table-column>
        <el-table-column prop="statustxt" label="申请状态" width="auto" align="center"></el-table-column>
       <el-table-column prop="zip" label="操作" width="auto" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button type="text" size="mini" @click="handleDel(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- <div class="flex-row justify-between group_30">
        <el-pagination background layout="total, prev, pager, next, jumper" @current-change="handleCurrentChange"
          :total="count">
        </el-pagination>
      </div> -->
    </div>
  </div>
</template>

<script>
  import interfaces from '../../../../utils/interface.js'
  export default {
    components: {},
    data() {
      return {
        selectId: 1, //选择切换
        //切换信息
        selectTitle: [{
          name: '学员信息',
          id: 1
        }, {
          name: '发证记录',
          id: 2
        }, {
          name: '开票信息',
          id: 3
        }],
        UserInfo: {
          email: '',
          mailing_addr: '',
          id_photo1: '',
          id_photo2: '',
          diploma_photo: '',
          mobile: '',
          enterprise_name: '',
        }, // 个人信息
        // 性别
        sexList: [{
          value: '0',
          label: '男'
        }, {
          value: '1',
          label: '女'
        }],
        sex_txt: '',
        // 学历
        educationNameList: [{
          value: '0',
          label: '男'
        }, {
          value: '1',
          label: '女'
        }],
        education_cate_name: '',
        uploadImg: interfaces.uploadImages, //上传图片接口地址
        closeDisabled: true, //不能修改
        certificateList: [], //发证记录
        pageCertificate: 1, //发证页数
        limitCertificate: 10, //发证条数
        pagenvIoiceLists: 1, //发票列表页数
        limitInvoiceLists: 10, //发票列表条数
        invoiceLists: [], //发票记录

        loading: false,
        page: 1,
        limit: 10,
        totalpage: 0,
        count: 0,
        trainObjectArr: [],
        trainObject: '',
        curriculumCateArr: [],
        curriculumCate: '',
        trainTypeArr: [],
        trainType: '',
        keyword: '',
        list: [],
        activeName: 'first',
        status: ''
      };
    },
    created() {
      let that = this;
      that.getUserInfo(); //获取个人信息
      that.getCertificateList(); //发证记录
      that.getUserInvoiceList(); //开票信息
      if(that.$route.query.selectId == 2){
       that.selectId = that.$route.query.selectId;
      }

    },
    methods: {
      //选择切换
      clickselectBox(e) {
        let that = this;
        that.selectId = e.target.dataset.id;
      },
      //获取个人信息
      getUserInfo() {
        let that = this;
        that.$request.getUserInfo().then((res) => {
          if (res.code == 200) {
            that.UserInfo = res.datas;
          }
        });
      },
      //修改个人信息
      getUpdateInfo() {
        let that = this;
        let param = {
          email: that.UserInfo.email,
          mailing_addr: that.UserInfo.mailing_addr,
          id_photo1: that.UserInfo.id_photo1,
          id_photo2: that.UserInfo.id_photo2,
          diploma_photo: that.UserInfo.diploma_photo,
          mobile: that.UserInfo.mobile,
          enterprise_name: that.UserInfo.enterprise_name,
        };
        that.$request.getUpdateInfo(param).then((res) => {
          if (res.code == 200) {
            // that.UserInfo = res.datas;
          } else {
            that.$myMessage.warning(res.msg);
            return false;
          }
        });
        that.getUserInfo();
      },
      // 修改资料
      editSubmit() {
        let that = this;
        that.closeDisabled = false;
      },
      // 保存修改
      sureSubmit() {
        let that = this;
        that.closeDisabled = true;
        that.getUpdateInfo();
      },
      // 发证记录
      getCertificateList() {
        let that = this;
        let param = {
          page: that.pageCertificate,
          limit: that.limitCertificate,
        };
        that.$request.getCertificateList(param).then((res) => {
          if (res.code == 200) {
            that.certificateList = res.datas;
          }
        });
      },
      //获取发票列表
      getUserInvoiceList() {
        let that = this;
        that.loading = true;
        let param = {
          page: that.pagenvIoiceLists,
          limit: that.limitInvoiceLists,
        };
        that.$request.getUserInvoiceList(param).then((res) => {
          if (res.code == 200) {
            that.invoiceLists = res.datas;
            if (that.invoiceLists.invoice_id1 == 1) {
              that.invoiceLists.invoice_name1 == '电子发票'
            } else {
              that.invoiceLists.invoice_name1 == '专用发票'
            }
            that.page = that.page + 1;
            that.totalpage = res.total_page;
            that.count = res.count;
            that.loading = false;
          }
        });
      },
      // 取消修改
      closeSubmit(){
        let that = this;
        that.closeDisabled = true;
      },
      // 删除
      handleDel(index, row) {
        
      },
      // 编辑
      handleEdit(index, row) {
        let that = this;
        let invoiceId = row.order_invoice_id;
        that.$router.push({
          path: '/user/invoice/info',
          query: {
            order_invoice_id: invoiceId
          }
        });
      },
	  //身份证上传成功
	  handleIdPhoto1Success(res, file) {
	    let that = this;
	    if (res.code == 200) {
	      console.log(file.raw);
	      that.loading = false;
	      that.UserInfo.id_photo1 = res.datas.location;
	    } else {
	      that.loading = false;
	      that.$myMessage.warning(res.msg);
	    }
	  },
	  //身份证上传成功
	  handleIdPhoto2Success(res, file) {
	    let that = this;
	    if (res.code == 200) {
	      console.log(file.raw);
	      that.loading = false;
	      that.UserInfo.id_photo2 = res.datas.location;
	    } else {
	      that.loading = false;
	      that.$myMessage.warning(res.msg);
	    }
	  },
	  //毕业证扫描件上传成功
	  handleDiplomaPhotoSuccess(res, file) {
	    let that = this;
	    if (res.code == 200) {
	      console.log(file.raw);
	      that.loading = false;
	      that.UserInfo.diploma_photo = res.datas.location;
	    } else {
	      that.loading = false;
	      that.$myMessage.warning(res.msg);
	    }
	  },
		beforeAvatarUpload(file) {
		  let that = this;
		  that.loading = true;
		  const isJPG = file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/jpeg';
		  const isLt2M = file.size / 1024 / 1024 < 2;
		
		  if (!isJPG) {
			that.loading = false;
			this.$message.error('上传图片只能是 jpg、jpeg、png 格式!');
		  }
		  if (!isLt2M) {
			that.loading = false;
			this.$message.error('上传图片大小不能超过 2MB!');
		  }
		  return isJPG && isLt2M;
		},
}
  };
</script>

<style scoped>
  .userinfo {
    margin: 1.5rem auto;
    padding: 2rem;
    width: 87%;
    height: calc(100vh - 7rem);
    background-color: #fff;
    border-radius: 5px;
  }

  .userinfo-cursor {
    cursor: pointer;
  }

  .userinfo-title-list {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 3px solid #0074FF;
    text-align: center;
  }

  .userinfo-title-item {
    width: 33.33%;
    line-height: 3;
  }

  .userinfo-title-item:not(:last-child) {
    border-right: solid #0074FF 3px;
  }

  .userinfo-title-active {
    color: #fff;
    background-color: #0074FF;
  }

  .userinfo-student {
    overflow-y: auto;
    margin: 2rem 3rem;
    height: 65vh;
  }

  .userinfo-student-img-box {
    display: flex;
    align-items: flex-end;
    color: #7D7D89;
    font-size: 0.75rem;
  }

  .userinfo-student-img {
    margin-right: 1rem;
    width: 8em;
    height: 10em;
    border: 1px dotted #D8D8D8;
  }

  .userinfo-student-img img {
    width: 100%;
    height: 100%;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #D8D8D8;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px rgba(240, 240, 240, .5);
    border-radius: 10px;
    background-color: rgba(240, 240, 240, .5);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 0px rgba(237, 44, 37, .5);
    background-color: #0074FF;
  }

  .userinfo-student .el-input,
  .userinfo-student .el-select {
    width: 600px;
  }

  .reg-user-info-master {
    color: #FF5F5A;
    font-size: 0.75rem;
  }

  .userinfo-student-btn {
    position: fixed;
    bottom: 8rem;
    right: 12rem;

  }
</style>
